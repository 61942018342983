<template>
  <div>
    <b-overlay
      :show="isFetching"
      spinner-variant="primary"
    >
      <b-card>
        <b-row class="d-flex">
          <b-col>
            <validation-provider
              #default="{ errors, failedRules }"
              name="event_name"
              rules="required"
            >
              <b-form-input
                ref="name"
                v-model="name"
                :placeholder="$t('Event Name')"
                class="input-h input-h4"
                :state="errors.length ? false : null"
                @blur="onBlurName(errors)"
              />
              <small class="text-danger">
                <template v-if="failedRules.required">{{ $t('validationErrorRequiredEventName') }}</template>
                <template v-else>{{ errors[0] }}</template>
              </small>
            </validation-provider>
          </b-col>
          <b-col
            cols="auto"
            style="padding-top: 9px"
          >
            <div class="d-flex">
              <feather-icon
                v-b-tooltip.hover.top="!isActive ? $t('tooltipShowInMenusList') : $t('tooltipHideInMenusList')"
                class="mr-2"
                :icon="isActive ? 'EyeIcon' : 'EyeOffIcon'"
                role="button"
                size="20"
                @click="onClickIsActive(!isActive)"
              />
              <icon-preview
                v-b-tooltip.hover.top="$t('Preview')"
                role="button"
                class="size-20 mr-2"
                @click.native="openPreviewModal"
              />
              <b-spinner
                v-if="isDuplicating"
                variant="primary"
                class="spinner-20 mr-2"
              />
              <feather-icon
                v-else
                v-b-tooltip.hover.top="$t('Duplicate')"
                class="mr-2"
                icon="CopyIcon"
                role="button"
                size="20"
                @click="onClickDuplicate"
              />
              <feather-icon
                v-b-tooltip.hover.top="$t('Delete')"
                class="text-danger"
                icon="TrashIcon"
                size="20"
                role="button"
                @click="isModalConfirmDelete = true"
              />
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-overlay
      v-show="isCardSchedules"
      :show="isFetching"
      spinner-variant="primary"
    >
      <card-schedules
        v-if="isCardSchedules"
        info="event"
      />
    </b-overlay>
    <b-overlay
      :show="isFetching"
      spinner-variant="primary"
    >
      <b-card class="mb-0">
        <b-card-title>
          {{ $t('eventDetailsTitle') }}
          <feather-icon
            icon="InfoIcon"
            role="button"
            size="20"
            style="vertical-align: top"
            class="ml-25 text-primary toggle-content"
            @click.stop="setIsInfo(['event', 'details'])"
          />
        </b-card-title>
        <b-collapse v-model="isInfo.event.details">
          <small class="d-block mb-2">
            {{ $t('infoEventDetails') }}
          </small>
        </b-collapse>
        <div class="mb-2">
          <b-form-group class="mb-0">
            <treeselect
              v-model="tables"
              v-b-tooltip.hover.top="$t('eventZoneTableTooltip')"
              :multiple="true"
              :options="optionsTablesTreeselect"
              :clearable="false"
              :placeholder="$t('eventZoneTablePlaceholder')"
              value-format="object"
              :no-children-text="$t('noChildrenZoneTable')"
              @close="onCloseTablesTreeselect"
            />
          </b-form-group>
          <b-row
            v-for="(item, itemIndex) in tables"
            :key="item.id"
            class="mt-1"
          >
            <b-col>
              <b-form-group
                v-if="itemIndex === 0"
                :label="$t('eventZoneTableLabel')"
                class="mb-0"
              />
              <template v-if="item.children">
                <b-link :to="{ name: 'zone', params: { id: item.id } }">
                  {{ item.label }}
                </b-link>
              </template>
              <template v-else>
                <b-link
                  :to="{
                    name: 'zone',
                    params: { id: optionsTablesTreeselect.find(i => i.children.find(j => j.id === item.id)).id }
                  }"
                  style="vertical-align: middle"
                >
                  {{ optionsTablesTreeselect.find(i => i.children.find(j => j.id === item.id)).label }}
                </b-link>
                <feather-icon
                  icon="ChevronRightIcon"
                  style="vertical-align: middle"
                  class="ml-25 mr-25"
                />
                <span style="vertical-align: middle">{{ $t('Table') }} {{ item.name }}</span>
              </template>
            </b-col>
            <b-col
              cols="auto"
              class="d-flex align-items-center"
              :style="{ paddingTop: itemIndex === 0 ? '27px' : null }"
            >
              <feather-icon
                size="20"
                role="button"
                icon="XCircleIcon"
                @click="onDeleteTable(item.id)"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mb-2">
          <b-form-group class="mb-0">
            <treeselect
              v-model="menus"
              v-b-tooltip.hover.top="$t('eventMenuTooltip')"
              :multiple="true"
              :disable-branch-nodes="true"
              :options="menusList.filter((i) => i.isPublished).map(i => ({ ...i, label: i.name }))"
              :clearable="false"
              :placeholder="$t('eventMenuPlaceholder')"
              value-format="object"
              :no-options-text="$t('noOptionsMenus')"
              @close="onCloseMenusTreeselect"
            />
          </b-form-group>
          <b-row
            v-for="(menu, menuIndex) in menus"
            :key="menu.id"
            class="mt-1"
          >
            <b-col>
              <b-form-group
                v-if="menuIndex === 0"
                :label="$t('eventMenuLabel')"
                class="mb-0"
              />
              <b-link :to="{ name: 'menu', params: { id: menu.id } }">
                {{ menu.name }}
              </b-link>
            </b-col>
            <b-col
              cols="auto"
              class="d-flex align-items-center"
              :style="{ paddingTop: menuIndex === 0 ? '27px' : null }"
            >
              <feather-icon
                size="20"
                role="button"
                icon="XCircleIcon"
                @click="onClickDeleteMenu(menu.id)"
              />
            </b-col>
          </b-row>
          <b-form-checkbox
            class="mt-1"
            switch
            :checked="isPriceVisible"
            @change="onChangeIsPriceVisible"
          >
            {{ $t('eventShowPriceLabel') }}
          </b-form-checkbox>
        </div>
        <b-form-group
          :label="$t('eventNotesLabel')"
          class="mb-0"
        >
          <validation-provider
            #default="{ errors, failedRules }"
            name="event_notes"
            :rules="`max:${textLimit}`"
          >
            <b-form-textarea
              v-model="notes"
              :placeholder="$t('eventNotesPlaceholder')"
              rows="4"
              no-resize
              :state="errors.length ? false : null"
              @blur="onBlurNotes(errors)"
            />
            <small
              v-if="errors.length"
              class="text-danger"
            >
              <template v-if="failedRules.max">{{ $t('validationErrorMaxEventNote', { slot: textLimit }) }}</template>
              <template v-else>{{ errors[0] }}</template>
            </small>
            <div
              v-else
              class="text-right"
            >
              <small>{{ notes.length }}/{{ textLimit }}</small>
            </div>
          </validation-provider>
        </b-form-group>
      </b-card>
    </b-overlay>
    <modal-preview
      v-model="isModalPreview"
      :link="previewUrl"
    />
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      :title="`${$t('eventDeleteTitle')}`"
      :is-message="false"
      @delete="onDelete"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BLink,
  VBTooltip,
  BOverlay,
  BSpinner,
  BCollapse,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required, max } from '@validations'
import Treeselect from '@riophae/vue-treeselect'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'
import ModalPreview from '@/components/modal/ModalPreview.vue'
import IconPreview from '@/components/icon/IconPreview.vue'
import mixinRequests from '@/mixins/requests'
import CardSchedules from '@/components/card/CardSchedules.vue'

import DELETE_EVENT from '@/gql/mutation/event/deleteEvent.gql'
import DUPLICATE_EVENT from '@/gql/mutation/event/duplicateEvent.gql'

export default {
  name: 'Event',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardTitle,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BLink,
    Treeselect,
    BOverlay,
    BSpinner,
    BCollapse,
    BFormCheckbox,
    ModalConfirmDelete,
    ModalPreview,
    IconPreview,
    CardSchedules,

    // validations
    ValidationProvider,
  },
  mixins: [
    mixinRequests,
  ],
  data() {
    return {
      isDuplicating: false,
      isFetching: true,

      name: '',
      tables: [],
      menus: [],
      notes: '',
      isActive: false,
      isPriceVisible: true,

      isModalConfirmDelete: false,
      isModalPreview: false,
      textLimit: 1000,

      // validation rules
      required,
      max,
    }
  },
  computed: {
    ...mapGetters({
      menusList: 'menuManagement/menus',
      restaurant: 'restaurant/restaurant',
      events: 'floorPlan/events',
      zones: 'floorPlan/zones',
      isInfo: 'main/isInfo',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
      isMenusFetched: 'menuManagement/isMenusFetched',
      isZonesFetched: 'floorPlan/isZonesFetched',
      isEventsFetched: 'floorPlan/isEventsFetched',
      isSchedulesFetched: 'schedules/isSchedulesFetched',
    }),
    previewUrl() {
      const queryParams = `restaurant=${this.restaurant ? this.restaurant.id : null}&event=${this.$route.params.id}`
      return `${process.env.VUE_APP_ORIGIN_CLIENT}/preview/catalog?${queryParams}`
    },
    isCardSchedules() {
      return this.isSubscriptionPremium
    },
    optionsTablesTreeselect() {
      return this.zones.map(i => ({
        ...i,
        label: i.name,
        children: i.tables
          .map(j => ({ ...j, label: j.name }))
          .sort((a, b) => a.label - b.label),
      }))
    },
  },
  watch: {
    isMenusFetched() {
      this.fetch()
    },
    isZonesFetched() {
      this.fetch()
    },
    isEventsFetched() {
      this.fetch()
    },
    isSchedulesFetched() {
      this.fetch()
    },
    $route: {
      immediate: true,
      handler() {
        this.fetch()
      },
    },
  },
  methods: {
    ...mapActions({
      setEvents: 'floorPlan/setEvents',
      setIsInfo: 'main/setIsInfo',
      setNavHistory: 'main/setNavHistory',
      updateEvent: 'floorPlan/updateEvent',
    }),
    openPreviewModal() {
      this.isModalPreview = true
    },

    patchZonesAndTablesInEvent() {
      const selectedTables = this.tables.filter(i => !i.children)
      const selectedTablesZones = selectedTables.map(i => this.zones.find(j => j.tables.find(k => k.id === i.id)))
      const selectedTablesZonesUniqIds = [...new Set(selectedTablesZones.map(i => i.id))]
      const excludedTables = selectedTablesZonesUniqIds
        .flatMap(i => this.zones.find(j => j.id === i).tables.map(j => j.id))
        .filter(i => !selectedTables.find(j => j.id === i))

      const zones = [
        ...this.tables.filter(i => i.children).map(i => this.zones.find(j => j.id === i.id)),
        ...selectedTablesZonesUniqIds.map(i => this.zones.find(j => j.id === i)),
      ]

      this.setEvents(this.events.map(i => (i.id === this.$route.params.id ? { ...i, excludedTables, zones } : i)))

      return [
        this.updateEvent([this.$route.params.id, 'excludedTables', excludedTables]),
        this.updateEvent([this.$route.params.id, 'zones', zones.map(i => i.id)]),
      ]
    },
    onCloseTablesTreeselect() {
      this.requests.push('patchZonesAndTablesInEvent')
    },
    onDeleteTable(id) {
      this.tables = this.tables.filter(i => i.id !== id)
      this.requests.push('patchZonesAndTablesInEvent')
    },

    patchEventIsPriceVisible() {
      this.setEvents(this.events.map(i => (i.id === this.$route.params.id
        ? { ...i, isPriceVisible: this.isPriceVisible }
        : i
      )))

      return [
        this.updateEvent([this.$route.params.id, 'isPriceVisible', this.isPriceVisible]),
      ]
    },
    onChangeIsPriceVisible(event) {
      this.isPriceVisible = event
      this.requests.push('patchEventIsPriceVisible')
    },

    patchEventActive() {
      this.setEvents(this.events.map(i => (i.id === this.$route.params.id ? { ...i, active: this.isActive } : i)))

      return [
        this.updateEvent([this.$route.params.id, 'active', this.isActive]),
      ]
    },
    onClickIsActive(value) {
      this.isActive = value
      this.requests.push('patchEventActive')
    },

    patchEventMenus() {
      // eslint-disable-next-line no-undef
      const menus = structuredClone(this.menus)
      this.setEvents(this.events.map(i => (i.id === this.$route.params.id ? { ...i, menus } : i)))

      return [
        this.updateEvent([this.$route.params.id, 'menus', this.menus.map(i => i.id)]),
      ]
    },
    onCloseMenusTreeselect() {
      this.requests.push('patchEventMenus')
    },
    onClickDeleteMenu(id) {
      this.menus = this.menus.filter(i => i.id !== id)
      this.requests.push('patchEventMenus')
    },

    patchEventDescription() {
      this.setEvents(this.events.map(i => (i.id === this.$route.params.id ? { ...i, description: this.notes } : i)))

      return [
        this.updateEvent([this.$route.params.id, 'description', this.notes]),
      ]
    },
    onBlurNotes(errors) {
      if (errors.length) {
        return
      }

      this.requests.push('patchEventDescription')
    },

    patchEventName() {
      this.setEvents(this.events.map(i => (i.id === this.$route.params.id ? { ...i, name: this.name } : i)))

      return [
        this.updateEvent([this.$route.params.id, 'name', this.name]),
      ]
    },
    onBlurName(errors) {
      if (this.name === this.events.find(i => i.id === this.$route.params.id).name) {
        return
      }

      if (errors.length) {
        return
      }

      this.requests.push('patchEventName')
    },

    async fetch() {
      if (!this.isMenusFetched || !this.isZonesFetched || !this.isEventsFetched || !this.isSchedulesFetched) {
        return
      }

      this.isFetching = true

      const history = ['event', this.$route.params.id]
      this.setNavHistory(history)

      const event = this.events.find(i => i.id === this.$route.params.id)
      if (!event) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('Not found'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        await this.$router.push({ name: 'events' })
        return
      }

      this.name = event.name

      let tables = []
      event.zones.forEach(i => {
        const zoneTables = this.zones.find(j => j.id === i.id).tables
        const excludedTables = zoneTables.filter(j => event.excludedTables.find(k => k === j.id))

        if (excludedTables.length) {
          tables = [
            ...tables,
            ...zoneTables
              .filter(j => !excludedTables.find(k => k.id === j.id))
              .map(j => this.optionsTablesTreeselect.find(k => k.id === i.id).children.find(k => k.id === j.id)),
          ]
        } else {
          tables = [...tables, this.optionsTablesTreeselect.find(j => j.id === i.id)]
        }
      })
      this.tables = tables

      // eslint-disable-next-line no-undef
      this.menus = structuredClone(event.menus)

      this.notes = event.description || ''
      this.isActive = event.active
      this.isPriceVisible = event.isPriceVisible

      if (this.$refs.name && this.$route.params.isFocusOnName) {
        this.$refs.name.focus()
      }

      this.isFetching = false
    },
    async onDelete() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_EVENT,
          variables: {
            deleteEventId: this.$route.params.id,
          },
        })

        this.setEvents(this.events.filter(i => i.id !== this.$route.params.id))

        await this.$router.push({ name: 'events' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessDeleted'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onClickDuplicate() {
      this.isDuplicating = true

      try {
        const response = await this.$apollo.mutate({
          mutation: DUPLICATE_EVENT,
          variables: {
            duplicateEventId: this.$route.params.id,
          },
        })

        // eslint-disable-next-line no-undef
        this.setEvents([...this.events, structuredClone(response.data.duplicateEvent)])

        await this.$router.push({
          name: 'event',
          params: {
            id: response.data.duplicateEvent.id,
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isDuplicating = false
      }
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/treeselect.sass'
</style>
